import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Provider, useSession, signIn } from 'next-auth/client';
import Image from 'next/image';
import Head from 'next/head';

import Footer from 'components/Footer';
import SignIn from 'components/SignIn';
import Register from 'components/Register';
import ForgotPassword from 'components/ForgotPassword';
import Header from 'components/Header';

import LandingPage from 'pages/landing-page';
import StartPage from 'pages/startpage';

import { LinksHeader, AppName, favicon } from 'infra/env-constants';

import '@styles/globals.scss';

import 'styles/Header.module.scss';
import '@styles/MenuLateral.css';
import UserBadgeProvider from 'context/BadgesContext';
import User from 'src/proxyClient/users';
import { getUserId } from 'src/common/authUtil';

import TagManager from 'react-gtm-module';

import cookieManager from 'mixins/cookieManager';
import queryObjectToString from 'mixins/queryObjectToString';
import supabase from 'services/supabase';
import OAuthCallback from './oauth-callback';

interface Props {
  pageProps: any;
  Component: any;
}

const isDevelopment = process.env.NEXT_PUBLIC_ENVIRONMENT === 'development';

function MyApp({ Component, pageProps }: Props) {
  const router = useRouter();
  const [session, loading] = useSession();
  const [profile, setProfile] = useState(null);
  const [skipHeader, setSkipHeader] = useState(false);

  const pathToSkipHeaderAndFooter = ['/parceiros/emprestimo-sim/', 'oauth-callback', '/admin/sorteio/transmissao', '/parceiros/noverde'];

  useEffect(() => {
    pathToSkipHeaderAndFooter.forEach((item) => {
      if (router.route.includes(item)) {
        setSkipHeader(true);
      }
    });

    if (session) {
      const getProfile = async (currentSession) => setProfile(
        await User.getUserProfileInfo(getUserId(currentSession)),
      );

      const condition = profile === null
        || cookieManager.getCookie('member_cookie_revalidate_profile');

      if (condition) {
        getProfile(session);
        cookieManager.eraseCookie('member_cookie_revalidate_profile');
      }
    }
  });

  const verifyEmail = (profileObject) => {
    if (router.pathname === '/verificar-email') return false;

    const excludeUrl = ['/', '/login', '/validar-email', 'oauth-callback'];

    const verifyCondition = !profileObject.emailVerified && !excludeUrl.includes(router.pathname);

    const urlRedirect = router.pathname === '/parceiros/emprestimo-sim/pre-analise'
      ? `&urlRedirect=${router.pathname}`
      : '';

    if (verifyCondition) {
      router.push(`/verificar-email?${queryObjectToString(router.query)}${urlRedirect}`);

      return false;
    }

    return true;
  };

  const verifyUncompleteData = () => {
    const skipProfileVerifyPaths = [
      '/',
      '/login',
      '/completar-perfil',
      '/validar-email',
      'oauth-callback',
    ];

    const verifyCondition = profile && !skipProfileVerifyPaths.includes(router.pathname);

    const verifyProfile = (profileObject) => {
      const uncompleteCondition = !profileObject.cep
        || !profileObject.gender
        || !profileObject.birthDate;

      const urlRedirect = router.pathname === '/parceiros/emprestimo-sim/pre-analise'
        ? router.pathname
        : undefined;

      if (uncompleteCondition) {
        router.push({
          pathname: '/completar-perfil',
          query: { ...router.query, urlRedirect },
        });
      }
    };

    if (verifyCondition) {
      verifyProfile(profile);
    }
  };

  useEffect(() => {
    if (profile) {
      const validEmailVerify = verifyEmail(profile);

      if (validEmailVerify) {
        verifyUncompleteData();
      }
    }
  });

  useEffect(() => {
    if (process.browser && process.env.NEXT_LOAD_ANALYTICS) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER,
        dataLayer: {
          page: {
            template: 'area-membros',
          },
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <Image
          src="/assets/icon-loading.svg"
          alt="loading"
          width={100}
          height={100}
        />
      </div>
    );
  }

  const user = supabase.auth.user();
  const isGoogleCrawlerLogin = !!(user && user.email === process.env.NEXT_PUBLIC_GOOGLE_CRAWLER_AD_BOT_LOGIN);

  if (!session && user) {
    signIn('supabase', { user: JSON.stringify(user) });
    /* if (!isGoogleCrawlerLogin) {
      signIn('supabase', { user: JSON.stringify(user) });
    } else {
      const { protocol, host } = window.location;

      signIn('supabase-email', {
        username: process.env.NEXT_PUBLIC_GOOGLE_CRAWLER_AD_BOT_LOGIN,
        password: process.env.NEXT_PUBLIC_GOOGLE_CRAWLER_AD_BOT_PASSWORD,
        callbackUrl: `${protocol}//${host}/login`,
        redirect: true,
      });
    } */
  }

  if (!session && !isDevelopment) {
    if (router.pathname === '/') {
      return <StartPage {...pageProps} />;
    }
    if (router.pathname === '/login') {
      return <SignIn />;
    }
    if (router.pathname === '/cadastro') {
      return <Register />;
    }
    if (router.pathname === '/esqueci-minha-senha') {
      return <ForgotPassword />;
    }
    if (router.pathname === '/landing-page') {
      return <LandingPage {...pageProps} />;
    }
    if (router.pathname === '/oauth-callback') {
      return <OAuthCallback />;
    }

    const urlRedirect = router.pathname === '/parceiros/emprestimo-sim/pre-analise'
      ? `&urlRedirect=${router.pathname}`
      : '';

    window.location.href = `/login?${queryObjectToString(router.query)}${urlRedirect}`;

    // A última linha evita que a página de conteúdo seja mostrado por uma fração de segundo
    return <SignIn />;
  }

  return (
    <div id="root">
      <Head>
        <title>{AppName}</title>
        <link rel="shortcut icon" href={favicon} />
      </Head>

      {!skipHeader && <Header isSignedIn links={LinksHeader} />}

      <UserBadgeProvider>
        <Provider session={pageProps.session}>
          <main>
            <Component {...pageProps} />
          </main>
        </Provider>
      </UserBadgeProvider>
      {!pathToSkipHeaderAndFooter.map((path) => path.includes(router.route)) && <Footer />}
    </div>
  );
}

export default MyApp;
