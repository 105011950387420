import React, { useEffect } from 'react';
import supabase from 'services/supabase';
import { signIn, getSession } from 'next-auth/client';
import cookieManager from '@mixins/cookieManager';
import Loader from '@components/Loader';

const OAuthCallback: React.FC = () => {
  useEffect(() => {
    const handle = async () => {
      const session = await getSession();

      const queryString = decodeURIComponent((cookieManager.getCookie('auth_query_string') ?? '')
        .replace(/\+/g, ' '));

      const homeUrl = `/home${queryString}`;
      // const redirectUrl = queryString.indexOf('urlRedirect') > -1 ? queryString.replace('?urlRedirect=', '') : homeUrl;
      const loginUrl = `/login${queryString}`;

      if (session) {
        location.href = homeUrl;
      }

      setTimeout(() => {
        const user = supabase.auth.user();

        if (user) {
          signIn('supabase', {
            user: JSON.stringify(user),
          });

          setTimeout(async () => {
            if (await getSession()) {
              location.href = homeUrl;

              return;
            }

            location.href = loginUrl;
          }, 1000);
        }
      }, 1000);
    };

    handle();
  });

  return (
    <Loader />
  );
};

export default OAuthCallback;
