
/* AUTOMATICALLY GENERATED FILE; EDIT WITH CARE */
/* eslint-disable import/prefer-default-export */
import { LinkModel } from '../models/links';

export type SocialMediaAccount = {
  link: string;
  name: string;
};

export const AppName = process.env.as ?? 'Plusdin Membro';

export const SocialMediaAccounts: SocialMediaAccount[] = [{"link":"https://www.facebook.com/Plusdin-101107134902695","name":"Facebook"},{"link":"https://www.instagram.com/plusdin/","name":"Instagram"},{"link":"https://linkedin.com/company/plusdin","name":"Linkedin"}];

export const LinksMenu: LinkModel[] = [{"name":"Início","id":1,"path":"/home"},{"name":"Ir para home plusdin","id":2,"path":"https://plusdin.com.br/"},{"name":"Promoções","id":3,"path":"/sorteios"},{"name":"Convidar Membros","id":4,"path":"/convidar-amigos"},{"name":"Editar Perfil","id":5,"path":"/editar-perfil"}];

export const LinksHeader: LinkModel[] = [{"name":"Início","id":1,"path":"/home"},{"name":"Ir para home plusdin","id":2,"path":"https://plusdin.com.br/"},{"name":"Promoções","id":3,"path":"/sorteios"},{"name":"Convidar Membros","id":4,"path":"/convidar-amigos"}];

export const saveSampling: number = 5;

export const favicon: string = 'https://plusdin.com.br/box/uploads/2021/02/cropped-fav_icon-254x254.png';

