import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import cookieManager from '@mixins/cookieManager';
import styles from '@styles/AdvisedEmailModal.module.scss';
import Image from 'next/image';
import Button from './Button';

const AdvisedEmailModal: React.FC = () => {
  const [, setIsEmailAdvised] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const verifyEmailAdvised = () => {
    const cookie = cookieManager.getCookie('member_email_valite_advised');

    setIsEmailAdvised(cookie !== null);

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleAdvisedButton = () => {
    const currentdate = new Date();
    const datetime = `${currentdate.getDate()}/${
      currentdate.getMonth() + 1}/${
      currentdate.getFullYear()} - ${
      currentdate.getHours()}:${
      currentdate.getMinutes()}:${
      currentdate.getSeconds()}`;

    const cookie = JSON.stringify({
      accepted: true,
      date: datetime,
    });

    cookieManager.setCookie('member_email_valite_advised', cookie, 9000);
    setIsEmailAdvised(true);
  };

  useEffect(() => {
    verifyEmailAdvised();
  }, [verifyEmailAdvised, setIsEmailAdvised]);

  return (
    <Modal
      open={false}
      onClose={() => { }}
    >
      <div className={styles.modalContainer}>
        <Image
          src="/assets/email-warning.svg"
          width="450"
          height="219"
        />

        <h1 className={styles.title}>
          Escolha o seu melhor e-mail!
        </h1>

        <div className={styles.content}>
          O Plusdin envia o <strong>link de ativação</strong> através do e-mail vinculado, por isso,
          lembre-se de escolher uma conta à qual você tenha acesso as mensagens.
        </div>

        <Button
          className={styles.button}
          disabled={isButtonDisabled}
          onClick={handleAdvisedButton}
          type="button"
        >
          Estou ciente
        </Button>
      </div>
    </Modal>
  );
};

export default AdvisedEmailModal;
