/* eslint-disable import/prefer-default-export */
import type { NextApiRequest } from 'next';

const sessionTokenKey = 'plusdin-members-auth.session-token';

interface AccessToken {
  sub: string;
}

export const getUserId = (session) => {
  const accessToken = (session?.accessToken as unknown) as AccessToken;
  return accessToken?.sub;
};

export const getSessionToken = (request: NextApiRequest) => request.cookies[sessionTokenKey];
