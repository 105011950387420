import { GetServerSideProps } from 'next';

import React, { useEffect } from 'react';
import { signIn } from 'next-auth/client';
import supabase from 'services/supabase';
import Router from 'next/router';

export const getServerSideProps: GetServerSideProps = async () => ({
  props: {},
});

export default function Startpage() {
  const iframeStyle = {
    height: '100vh',
    overflow: 'hidden',
  };

  useEffect(() => {
    const user = supabase.auth.user();

    if (user) {
      signIn('supabase', {
        user: JSON.stringify(user),
      });

      return;
    }

    Router.push('/login');
  });

  return (
    <iframe title="Plusdin Membros" src="https://brius.com.br/membros/" style={iframeStyle} frameBorder="0" height="100%" width="100%" />
  );
}
