import React from 'react';
import TextInput from 'components/TextInput';
import styles from '@styles/Register.module.scss';

const RegisterForm: React.FC = () => (
  <form action="#" className={styles.form}>
    <div className={styles.form_field}>
      <TextInput
        id="name"
        label="Seu nome:"
        placeholder="Digite seu nome"
        type="text"
      />
    </div>

    <div className={styles.form_field}>
      <TextInput
        id="e-mail"
        label="Seu e-mail:"
        placeholder="Digite seu e-mail"
        type="text"
      />
    </div>

    <div className={styles.form_field}>
      <TextInput
        id="password"
        label="Sua senha:"
        placeholder="Digite sua senha:"
        type="text"
      />
    </div>

    <div className={styles.form_field}>
      <TextInput
        id="password"
        label="Confirme sua senha:"
        placeholder="Digite sua senha:"
        type="text"
      />
    </div>

    <span role="button" tabIndex={0} className={styles.actionBtn}>
      <span className={`${styles.text} ${styles.highlight_text}`}>Salvar</span>
    </span>
  </form>
);

export default RegisterForm;
