import React from 'react';
import styles from '@styles/Register.module.scss';
import RegisterForm from '@components/RegisterForm';

import Image from 'next/image';
import { AppName } from '../infra/env-constants';

const message = 'Cadastre-se';
const Register: React.FC = () => (
  <div id="register" className={styles.container}>
    <Image src="/assets/logo.svg" alt={AppName} width={171} height={73} />
    <div className={styles.card}>
      <h3 className={styles.title}>{message}</h3>
      <RegisterForm />

      <div className={styles.separator}>
        <span className={styles.line} />
        <p>ou</p>
        <span className={styles.line} />
      </div>

      <span role="button" tabIndex={0} className={styles.customBtn}>
        <img className={styles.icon} src="/assets/icon-google.svg" alt={AppName} width={16} height={16} />
        {' '}
        <span className={`${styles.text} ${styles.highlight_text}`}>Cadastre-se com Google</span>
      </span>

      <span className={styles.text}>
        Já é membro?&nbsp;
        <a
          href="/login"
          className={styles.underline_text}
        >
          Entre
        </a>
      </span>

    </div>

    <div className={styles.ellipsis} />
  </div>
);

export default Register;
