export const STATUS_CODE = {
  BAD_REQUEST: 400,
  CREATED: 201,
  NO_CONTENT: 204,
};

export const METHODS = {
  POST: 'POST',
};

export const MIME = {
  JSON: 'application/json',
};
