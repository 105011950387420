/* eslint-disable react/button-has-type */
import React from 'react';

import Utils from 'src/infra/utils';

import styles from 'styles/Button.module.scss';

import Image from 'next/image';

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type: 'submit' | 'button';
  children: React.ReactNode;
  onClick?: Function;
  color?: string,
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  type = 'button',
  className: parentClassName,
  onClick,
  color = 'primary',
}) => {
  const clicked = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <button
      className={Utils.combineStyles(parentClassName, styles.default, styles[color])}
      disabled={disabled}
      type={type}
      onClick={clicked}
    >
      {children}
      {loading
        && <Image src="/assets/icon-loading-white.svg" alt="loading" width={30} height={30} />}
    </button>
  );
};

export default Button;
