import React, { useEffect, useState } from 'react';
import styles from '@styles/SignIn.module.scss';
import { signIn } from 'next-auth/client';
import { set } from 'idb-keyval';
import cookieCutter from 'cookie-cutter';

// import SignInForm from 'components/SignInForm';
import supabase from 'services/supabase';
import { useRouter } from 'next/router';
import AdvisedEmailModal from './AdvisedEmailModal';
import { AppName } from '../infra/env-constants';

const CRAWLER_LOGIN_VALUE = 'google-ads-bot';
const CRAWLER_LOGIN_SECUTIRY_KEY = 'google-ads-crawler-sign-in-plusdin';

const SignIn: React.FC = () => {
  const router = useRouter();
  const [showGoogleLogin, setShowGoogleLogin] = useState(false);
  const message = 'Para acessar:';
  const [errorMessage, setErrorMessage] = useState('');
  const [isGoogleAdsCrawlerLogin, setIsGoogleAdsCrawlerLogin] = useState(false);

  const tryToLogin = (e, authProviderName) => {
    // eslint-disable-next-line
    e.preventDefault();

    const urlParams = new URLSearchParams(window.location.search);
    const inviteOrigin = urlParams.get('invite-origin');
    const drawingOrigin = urlParams.get('drawing-hash');

    if (inviteOrigin) {
      set('members_invite_origin', inviteOrigin);
    }

    if (drawingOrigin) {
      set('members_invite_drawing', drawingOrigin);
    }

    const now = new Date();
    const time = now.getTime();
    const expireTime = time + 1000 * 1000;
    now.setTime(expireTime);

    const cookieOptions = {
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      expires: now.toUTCString(),
    };

    cookieCutter.set('auth_members_provider', authProviderName, cookieOptions);
    const redirectBaseUrl = `${window.location.protocol}//${window.location.host}`;
    const callBackRedirectUrl = `${redirectBaseUrl}/oauth-callback`;
    cookieCutter.set('auth_query_string', window.location.search);

    setTimeout(async () => {
      if (authProviderName === 'google-crawler') {
        const { securityKey } = router.query;
        const decryptedSecurityKey = securityKey ? atob(securityKey.toString()) : null;

        if (!decryptedSecurityKey) {
          setErrorMessage('A chave de segurança deve ser informada.');
          return;
        }

        if (decryptedSecurityKey !== CRAWLER_LOGIN_SECUTIRY_KEY) {
          setErrorMessage('A chave de segurança informada está errada.');
          return;
        }

        const { protocol, host } = window.location;

        const signInConfigurationObject = {
          username: process.env.NEXT_PUBLIC_GOOGLE_CRAWLER_AD_BOT_LOGIN,
          password: process.env.NEXT_PUBLIC_GOOGLE_CRAWLER_AD_BOT_PASSWORD,
          callbackUrl: `${protocol}//${host}/login`,
          redirect: true,
        };

        signIn('supabase-email', signInConfigurationObject);
      } else {
        await supabase.auth.signIn({ provider: authProviderName }, { redirectTo: callBackRedirectUrl });
      }
    }, 500);
  };

  useEffect(() => {
    const { crawlerAuthentication } = router.query;
    const decryptedCrawlerAuthentication = crawlerAuthentication ? atob(crawlerAuthentication.toString()) : null;

    if (decryptedCrawlerAuthentication && decryptedCrawlerAuthentication === CRAWLER_LOGIN_VALUE) {
      setIsGoogleAdsCrawlerLogin(true);
      const virtualEvent = new CustomEvent('virtualhover', { bubbles: true, detail: 'red' });
      tryToLogin(virtualEvent, 'google-crawler');
    } else {
      const user = supabase.auth.user();

      if (user) {
        signIn('supabase', {
          user: JSON.stringify(user),
        });
      }
    }

    const webview = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(navigator.userAgent);

    if (!webview) {
      setShowGoogleLogin(true);
    }
  });

  return (
    <div id="signIn" className={styles.container}>
      <AdvisedEmailModal />
      <img
        src="/assets/logo.svg"
        alt={AppName}
        width={171}
        height={73}
        className={styles.logo}
      />

      <div className={styles.card}>
        {errorMessage && errorMessage !== ''
          && <span>{errorMessage}</span>}

        {!isGoogleAdsCrawlerLogin
          && (
          <>
            <h3 className={styles.title}>{message}</h3>

            {showGoogleLogin
                && (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => tryToLogin(e, 'google')}
                  className={styles.customBtn}
                  onClick={(e) => tryToLogin(e, 'google')}
                >
                  <img
                    className={styles.icon}
                    src="/assets/icon-google.svg"
                    alt={AppName}
                    width={16}
                    height={16}
                  />{' '}
                  <span className={styles.text}>Entrar com Google</span>
                </span>
                )}
            <span
              role="button"
              tabIndex={0}
              onKeyDown={(e) => tryToLogin(e, 'facebook')}
              className={styles.customBtn}
              onClick={(e) => tryToLogin(e, 'facebook')}
            >
              <img
                className={styles.icon}
                src="/assets/icon-facebook-blue.svg"
                alt={AppName}
                width={16}
                height={16}
              />{' '}
              <span className={styles.text}>Entrar com Facebook</span>
            </span>
          </>
          )}
      </div>

      <div className={styles.ellipsis} />
    </div>
  );
};

export default SignIn;
