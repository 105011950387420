/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Link from 'next/link';

import Logo, { Variants } from 'components/Logo';

import Footer from 'components/Footer';

import Utils from 'src/infra/utils';
import CourseCard, { CourseCardProps } from 'components/CourseCard';
import MenuLateral from 'components/MenuLateral';
import { LinkModel } from 'models/links';

import styles from 'styles/landingPage.module.scss';

interface SubMenuLink {
  text: string;
  link: string;
  img: string;
}

interface SubMenu {
  text: string;
  link: string;
  children?: SubMenuLink[];
}
interface Menu {
  text: string;
  link: string;
  children?: SubMenu[];
}

interface Section {
  title: string;
  description: string;
  buttonTextMobile: string;
  buttonText: string;
}

interface Card {
  imageUrl: string;
  title: string;
  description: string;
}

interface Last {
  phrase: string;
  buttonTextMobile: string;
  buttonText: string;
}

export interface LandingPageComponentProps {
  menu: Menu[];
  main: Section;
  secondary: Section;
  cards: Card[];
  last: Last;
}

interface LastPhraseProps {
  children: React.ReactNode;
}

const LastPhrase: React.FC<LastPhraseProps> = ({ children }) => (
  isMobile ? <p>{children}</p> : <h1>{children}</h1>
);

const parseCourseProps = (course: Card): CourseCardProps => ({
  title: course.title,
  description: course.description,
  imgUrl: `${process.env.NEXT_PUBLIC_STORAGE_URL}/${course.imageUrl}`,
  status: undefined,
});

const createMenuLinks = (items: Menu[]): LinkModel[] => items.map((m, i) => ({
  id: i,
  name: m.text,
  path: m.link,
  children: m.children,
}));

const LandingPageComponent: React.FC<LandingPageComponentProps> = ({
  menu,
  main,
  secondary,
  last,
  cards,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onStateChange = ({ isOpen }) => {
    setMenuIsOpen(isOpen);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.circle} />
      <header className={styles.header}>
        <Logo variant={Variants.ldpage} size />
        <nav className={styles.navMobile}>
          <Link href="/login"><span className={styles.accessButton}>Acesse</span></Link>
          <MenuLateral
            links={createMenuLinks(menu)}
            onStateChange={onStateChange}
            open={menuIsOpen}
            closeMenu={closeMenu}
            isSignedIn={false}
          />
        </nav>
        <nav className={styles.navDesktop}>
          {
            menu.filter((item) => !!item.children).map((item) => (
              <div
                className={styles.menuItemFix}
                key={item.link}
              >{item.text}{'  '}
                <img
                  src="/assets/arrow-select.svg"
                  alt="loading"
                  width={10}
                  height={10}
                  style={{
                    marginLeft: '0.5rem',
                  }}
                />
                <ul
                  className={styles.menuItemFixContent}
                >
                  {item.children.filter((subitem) => !!subitem.children).map((subitem, index) => (
                    <li key={`${subitem}${index+1}`}>
                      <div>
                        <span>
                          {subitem.text}
                        </span>
                        <img
                          src="/assets/chevron-right.svg"
                          alt="loading"
                          width={20}
                          height={20}
                          style={{ float: 'right' }}
                        />
                      </div>
                      <div
                        className={styles.subMenuItemFixContent}
                      >
                        {menu[0].children[0].children
                          .map((child) => (
                            <a
                              key={child.link}
                              href={child.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={child.img}
                                alt="loading"
                                width={20}
                                height={20}
                                className={styles.linkLogo}
                              />
                              <span
                                style={{
                                  paddingRight: '0.5rem',
                                }}
                              >
                                {child.text}
                              </span>
                            </a>
                          ))}
                      </div>
                    </li>
                  ))}
                  {item.children.filter((subitem) => !subitem.children).map((subitem) => (
                    <li key={subitem.link}>
                      <a
                        key={subitem.link}
                        href={subitem.link}
                      >
                        <span>
                          {subitem.text}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          }
          <div>
            {menu.filter((item) => !item.children).map((item) => (
              <a className={styles.menuItem} key={item.link} href={item.link}>{item.text}</a>
            ))}
          </div>
        </nav>
      </header>

      <main className={styles.main}>
        <section className={styles.firstSection}>
          <div className={styles.titleWrapper}>
            <h1>{main.title}</h1>
          </div>
          <p>{main.description}</p>
          <a
            href="/login"
            className={Utils.combineStyles(styles.buttonLink, styles.contrast, styles.maxWidth)}
          >
            {isMobile ? main.buttonTextMobile : main.buttonText}
          </a>
          <div className={styles.blankSpace} />
        </section>

        <section className={styles.secondSection}>
          <h1>{secondary.title}</h1>
          <p>{secondary.description}</p>
          <a
            href="/login"
            className={Utils.combineStyles(styles.buttonLink, styles.primary)}
          >
            {isMobile ? secondary.buttonTextMobile : secondary.buttonText}
          </a>
        </section>

        <section className={styles.thirdSection}>
          {
            cards.map((course) => (
              <a key={`cc-${course.title}`} href="login">
                <CourseCard
                  {...parseCourseProps(course)}
                />
              </a>
            ))
          }
        </section>

        <section className={styles.fourthSection}>
          <LastPhrase>{last.phrase}</LastPhrase>
          <a
            href="/login"
            className={Utils.combineStyles(styles.buttonLinkEnd, styles.primary)}
          >
            {isMobile ? last.buttonTextMobile : last.buttonText}
          </a>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPageComponent;
