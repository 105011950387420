import React from 'react';
import Image from 'next/image';

import { AppName } from 'infra/env-constants';

interface FooterProps {
  variant?: Variants;
  size?: boolean;
}

export enum Variants {
  footer,
  ldpage,
}

const getSrc = (variant?: Variants) => {
  if (variant === Variants.footer) {
    return '/assets/footer-logo.svg';
  }
  if (variant === Variants.ldpage) {
    return '/assets/logo-landing-page.svg';
  }
  return '/assets/logo.svg';
};

const Logo: React.FC<FooterProps> = ({ variant, size }) => (
  <Image
    src={getSrc(variant)}
    alt={AppName}
    width={size? 150 : 120}
    height={size ? 48 : 32}
  />
);

export default Logo;
