import React, { createContext, useState, SetStateAction } from 'react';
import { BadgesInfo } from '../src/apiClient/users';

export const UserBadgeContext = createContext({} as UserBadgeData);

interface UserBadgeProps{
  children: React.ReactNode;
}

interface UserBadgeData{
  userBadges : BadgesInfo[],
  setUserBadges: (userBadgesArray : SetStateAction<BadgesInfo[]>) => void,
  dateMostRecentlyAquired: Date,
  setDateMostRecentlyAquired: (lastDate : SetStateAction<Date>) => void,
}

const UserBadgeProvider = ({ children } : UserBadgeProps) => {
  const [userBadges, setUserBadges] = useState([] as BadgesInfo[]);
  const [dateMostRecentlyAquired, setDateMostRecentlyAquired] = useState(undefined);

  return (
    <UserBadgeContext.Provider
      value={{
        userBadges,
        setUserBadges,
        dateMostRecentlyAquired,
        setDateMostRecentlyAquired,
      }}
    >
      {children}
    </UserBadgeContext.Provider>
  );
};

export default UserBadgeProvider;
