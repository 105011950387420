import React from 'react';

import styles from 'styles/CourseCard.module.css';

interface StatusProps{
  iconImgUrl?: string;
  label: string;
}

export interface CourseCardProps {
  imgUrl: string;
  title: string;
  description: string;
  status: StatusProps;
}

const CourseCard: React.FC<CourseCardProps> = ({
  imgUrl,
  title,
  description,
  status,
}) => (
  <article className={styles.courseCardContainer} data-cy="CourseComponent">
    <img
      data-cy="imgCourseComponent"
      src={imgUrl}
      alt={title}
      className={styles.imgCourseCard}
    />

    <div className={styles.divInfoCourse} data-cy="titleCourseComponentWrapper">
      <h3
        data-cy="titleCourseComponent"
        className={styles.titleCourseCard}
      >
        {title}
      </h3>

      <p
        data-cy="descriptionCourseComponent"
        className={styles.descriptionCourseCard}
      >
        {status?.label !=='Concluído' ? description: ''}
      </p>

      <div className={styles.divStatusCourseCard}>
        {
          status?.iconImgUrl && (
          <img
            data-cy="statusIconCourseComponent"
            className={styles.iconStatus}
            src={status.iconImgUrl}
            alt=""
          />
          )
        }

        {status && (
          <span
            data-cy="statusLabelCourseComponent"
            className={styles.labelStatus}
          >
            {status.label}
          </span>
        )}
      </div>
    </div>
  </article>
);

export default CourseCard;
