import React from 'react';
import Image from 'next/image';
import {
  AppName,
  SocialMediaAccount,
  SocialMediaAccounts,
} from '@infra/env-constants';
import { isMobile } from 'react-device-detect';

import styles from '../styles/Footer.module.scss';

export function getSocialMediaImage(socialMediaName: SocialMediaAccount['name']) {
  const socialMediaMap: { [key in typeof socialMediaName]: string } = {
    Instagram: '/assets/icon-instagram.svg',
    Facebook: '/assets/icon-facebook.svg',
    Linkedin: '/assets/icon-linkedin.svg',
  };
  return socialMediaMap[socialMediaName] ?? '';
}

export default function Footer() {
  return (
    <footer
      className={styles.footer}
      data-cy="footer"
    >
      <div
        className={styles.container}
      >
        <section>
          <a
            href="https://plusdin.com.br/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image
              src="/assets/footer-logo.svg"
              alt={AppName}
              width={isMobile ? 93 : 134}
              height={isMobile ? 28 : 40}
            />
          </a>

          <div
            className={styles.socialMediaArea}
          >
            {SocialMediaAccounts.map((socialMedia) => (
              <a
                key={socialMedia.name}
                href={socialMedia.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={getSocialMediaImage(socialMedia.name)}
                  alt={`${AppName} no ${socialMedia.name}`}
                />
              </a>
            ))}
          </div>
        </section>
      </div>
    </footer>
  );
}
