import React from 'react';
import styles from 'styles/LoaderComponent.module.scss';

const Loader = () => (
  <section
    className={styles.loadingComponent}
  >
    <div className={styles.main}>
      <div className={styles.container}>
        <img
          id="new-loader"
          src="https://storage.googleapis.com/assets.plusdin.com/loader.gif"
          alt="Plusdin"
          className={`${styles.loaderGif} hidden`}
        />
      </div>

      <div className={styles.plcSafeSite}>
        <a
          href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fmembros.plusdin.com.br"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/safe-site.svg"
            alt="Plusdin's safe site"
          />
        </a>
      </div>
    </div>
  </section>
);

export default Loader;
