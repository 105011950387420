import React from 'react';
import styles from '@styles/ForgotPassword.module.scss';
import Image from 'next/image';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import { AppName } from '../infra/env-constants';

const message = 'Recuperar Senha';
const cardTitleMessage = 'Insira o e-mail cadastrado abaixo e lhe enviaremos um e-mail com instruções para recuperar o seu acesso e criar uma nova senha';

const ForgotPassword: React.FC = () => (
  <div id="forgotPassword" className={styles.container}>
    <Image src="/assets/logo.svg" alt={AppName} width={171} height={73} />
    <h3 className={styles.title}>{message}</h3>
    <div className={styles.card}>
      <p className={styles.card_title}>
        {cardTitleMessage}
      </p>

      <ForgotPasswordForm />
    </div>

    <div className={styles.ellipsis} />
  </div>
);

export default ForgotPassword;
