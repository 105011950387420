import React, { useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import Image from 'next/image';
import { signOut } from 'next-auth/client';
import Link from 'next/link';

import { LinkModel } from 'models/links';
import { AppName } from 'infra/env-constants';

import styles from 'styles/landingPage.module.scss';

interface Props {
  links: LinkModel[];
  onStateChange: (e: {isOpen: boolean}) => void;
  open: boolean;
  closeMenu: () => void;
  isSignedIn: boolean;
}

const MenuLateral: React.FC<Props> = ({
  links, onStateChange, open, closeMenu, isSignedIn = true,
}) => {
  useEffect(() => {
    const btn: HTMLScriptElement = document.querySelector('#react-burger-menu-btn');
    btn.style.position = 'absolute';
  }, []);

  return (
    <Menu
      id="Menu"
      disableAutoFocus
      right
      isOpen={open}
      onStateChange={onStateChange}
      customBurgerIcon={<img src="/assets/icon-burger.svg" alt="Abrir menu" />}
      customCrossIcon={<img src="/assets/icon-cross.svg" alt="Fechar menu" />}
    >
      <ul>
        {links.filter((link) => !!link.children).map((link: LinkModel) => (
          <div key={link.id} className="link">
            <li key="mainLink">{link.name}</li>
            {link.children.map((sublink) => (
              <li
                key={sublink.text}
                className={styles.sideMenu}
              >
                <Link href={sublink.link}>
                  <span
                    onKeyDown={() => { closeMenu(); }}
                    onClick={() => { closeMenu(); }}
                    role="button"
                    tabIndex={0}
                    className={styles.sideMenuText}
                  >
                    {sublink.text}
                  </span>
                </Link>
              </li>
            ))}
          </div>
        ))}
        {links.filter((link) => !link.children).map((link: LinkModel) => (
          <li key={link.id} className="link">
            <Link href={link.path}>
              <span
                onKeyDown={() => { closeMenu(); }}
                onClick={() => { closeMenu(); }}
                role="button"
                tabIndex={0}
              >
                {link.name}
              </span>
            </Link>
          </li>
        ))}

        {
          isSignedIn && (
            <li key="signOut" className="link">
              <span
                role="button"
                onClick={(e) => { e.preventDefault(); signOut(); }}
                onKeyDown={(e) => { e.preventDefault(); signOut(); }}
                tabIndex={0}
              >
                Sair
              </span>
            </li>
          )
        }
      </ul>

      <span className="logo-menu">
        <Image src="/assets/logo.svg" width={75} height={32} alt={AppName} />
      </span>
    </Menu>
  );
};

export default MenuLateral;
