const combineStyles = (...stylesList: string[]): string => stylesList.join(' ');

function formatDate(oldDate: Date | undefined) {
  if (!oldDate) {
    return '--/--/----';
  }
  const newDate = new Date(oldDate);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}

const formatName = (name: string, isMobile: boolean) : string => {
  const result = name.toUpperCase().trim().split(' ');
  if (isMobile) {
    return result[0];
  }
  return name;
};

const getInitials = (name: string) : string => {
  const result = name.toUpperCase().trim().split(' ');
  if (result.length === 1) {
    return result[0][0];
  }
  return `${result[0][0]}${result[1][0]}`;
};

export default {
  combineStyles,
  formatDate,
  formatName,
  getInitials,
};
